<template>
  <div class="upload-container">
    <div v-if="urls.length > 0 && type == 'image'">
      <div class="img-wrapper" v-for="(item, index) in urls" :key="index">
        <!-- 加个删除按钮 -->
        <i
          class="el-icon-circle-close close-btn"
          v-on:click="removeFile(index)"
        />
        <el-image fit="cover" :src="item" />
      </div>
    </div>
    <div v-if="urls.length > 0 && (type == 'video' || type == 'mp4')">
      <div class="img-wrapper" v-for="(item, index) in urls" :key="index">
        <!-- 加个删除按钮 -->
        <i
          class="el-icon-circle-close close-btn"
          v-on:click="removeFile(index)"
        />
        <video controls width="100%" :src="item" />
      </div>
    </div>
    <el-upload
      ref="upload"
      class="image-uploader"
      :multiple="limit > 1"
      :show-file-list="false"
      drag
      action
      :http-request="uploadFunc"
      :limit="limit"
      v-if="urls.length < limit"
    >
      <!-- :before-upload="before" -->
      <div>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip" style="margin-top:-10px">
          {{text}}
        </div>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { putObject } from "@/api/cos";
import {upLoad} from "@/api/index"
export default {
  name: "upload",
  props: {
    value: [String, Array],
    limit: {
      type: Number,
      default: 1,
    },
    // 上传文件类型 图片image 视频video
    type: {
      type: String,
      default: "image",
    },
    text:{
      type:String,
      default:"(上传jpg/png文件，1980*1080)"
    }
  },
  data() {
    return {
      urls: [],
    };
  },
  watch: {
    value: {
      handler: function () {
        if (typeof this.value == "string") {
          this.urls = this.value ? [this.value] : this.value;
        } else {
          this.urls = this.value || [];
        }
      },
      deep: true,
    },
    type: {
      handler: function () {
        // 类型切换，初始化上传内容
        this.urls = [];
        this.$emit("input", "");
      },
    },
  },
  mounted() {
    if (typeof this.value == "string") {
      this.urls = this.value ? [this.value] : [];
    } else {
      this.urls = this.value || [];
    }
  },
  methods: {
    // before() {
    //   this.$refs.upload.clearFiles();
    // },
     async uploadFunc(file) {
      const loading = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // console.log(file.file);
      let param = new FormData();
        param.append("file", file.file);
      let res = await upLoad(param)
      console.log(res);
      loading.close();
      this.$emit("input",res.data.url,123);


      // console.log(res);
      // console.log(file.file)
      // if (file.file.type.indexOf(this.type) == -1) {
      //   loading.close();
      //   return this.$message.error("上传文件格式不符合藏品类型要求");
      // }
      // putObject(file.file)
      //   .then((url) => {
      //     console.log(url);
      //     loading.close();
      //     this.urls = [...this.urls, url];
      //   })
      //   .catch((e) => {
      //     loading.close();
      //     console.log("error", e);
      //   });
    },
    removeFile(index) {
      this.urls.splice(index, 1);
      // 如果数据没响应放开下面的注释,重新调用vue的数据响应
      this.$nextTick(() => {
        this.urls = Object.assign([], this.urls);
        this.$emit("input", this.urls.length == 1 ? this.urls[0] : this.urls);
      });
    },
  },
};
</script>

<style  scoped>
.img-wrapper {
  position: relative;
}
::v-deep .el-upload-dragger {
  overflow: visible;
}
.close-btn {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  z-index: 999;
  right: calc(30px / 2.5 * -1);
  top: calc(30px / 2.5 * -1);
}

.close-btn:hover {
  cursor: pointer;
}
</style>
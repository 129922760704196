import Vue from 'vue'
import App from './App.vue'
import router from "./router/index" //导入路由
// import store from './store/index'; //导入vuex


// ElementUI的导入和使用
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)


Vue.config.productionTip = false;
Vue.use(ElementUI);

// const originalPush = router.prototype.push;
// router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// };
new Vue({
    render: h => h(App),
    router,
    beforeCreate() {
        // Vue.prototype.$bus = this //给Vue原型上添加一个全局的事件总线
    }
}).$mount('#app')
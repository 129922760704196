// 当前这个模块：API进行统一的管理
import requests from "./request";
// 上传文件
export const upLoad = (obj) => requests({
    url: "/file/upLoad",
    method: "post",
    data: obj,
    headers: {
        "Content-Type": "multipart/form-data",
        'token': window.sessionStorage.getItem("cpopToken")
    }
})

// 作品列表
export const worksPageList = (obj) => requests({
    url: "/works/worksPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})
// 作品上传
export const worksAdd = (obj) => requests({
    url: "/works/worksAdd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})
// 作品编辑
export const worksUpd = (obj) => requests({
    url: "/works/worksUpd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})

// 删除作品
export const worksDel = (obj) => requests({
    url: "/works/worksDel",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})

// 上/下架
export const worksOpenUp = (obj) => requests({
    url: "/works/worksOpenUp",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})
// 调整票数
export const worksNumberUpd = (obj) => requests({
    url: "/works/worksNumberUpd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})
// 华流推荐
export const worksCom = (obj) => requests({
    url: "/works/worksCom",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})

// 华流推荐列表
export const worksComList = (obj) => requests({
    url: "/works/worksComList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("cpopToken")
    }
})


// 文件上传
// export const regionQrCodeMechanismPageList = (obj) => requests({
//     url: "/ass/charTag/regionQrCodeMechanismPageList",
//     method: "post",
//     data: obj,
//     headers: {
//         'Content-Type': 'application/json',
//         'token': window.sessionStorage.getItem("token")
//     }
// })
<template>
  <el-container>


    <el-container>
      <!-- 顶部栏 -->
      <el-header style="height: 50px">
        <el-button type="info" size="small" class="quit" @click="quitClick">
          退出</el-button
        >
        <div class="nowTime">{{ nowTime }}</div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div style="overflow: auto; height: 90vh">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      nowTime: "", //用于存储当前时间
      menuList: [], //菜单栏数组
      // 当前被激活的地址
      activepath: "",
    };
  },

  created() {
    // 初始化菜单数据  获取到本地存储的数据
    // this.menuList = JSON.parse(sessionStorage.getItem("menuList"));
    // 刷新保存当前打开的页面
    // this.activepath = sessionStorage.getItem("activepath");
  },

  methods: {
    currentTime() {
      //定时器，每隔0.5秒调用一次方法
      setInterval(this.getCurrentTime, 1000);
    },
    // 获取当前时间并格式化方法
    getCurrentTime() {
      var newdate = new Date();
      var week = newdate.getDay();
      var weekarr = new Array("日", "一", "二", "三", "四", "五", "六");
      var time = " ";
      time += newdate.getFullYear() + "-";
      time +=
        newdate.getMonth() + 1 < 10
          ? "0" + (newdate.getMonth() + 1)
          : newdate.getMonth() + 1;
      time += "-";
      time +=
        newdate.getDate() < 10 ? "0" + newdate.getDate() : newdate.getDate();
      time += "  ";
      time += "星期" + weekarr[week] + " ";
      time +=
        newdate.getHours() < 10 ? "0" + newdate.getHours() : newdate.getHours();
      time += ":";
      time +=
        newdate.getMinutes() < 10
          ? "0" + newdate.getMinutes()
          : newdate.getMinutes();
      time += ":";
      time +=
        newdate.getSeconds() < 10
          ? "0" + newdate.getSeconds()
          : newdate.getSeconds() + "";
      this.nowTime = time;
    },
    // 退出按钮监听事件
    quitClick() {
      // 清除本地缓存
      sessionStorage.clear();
      // 路由跳转
      this.$router.push("/Login");
      this.$message.error("您已退出系统！");
    },
    saveNavState(activepath) {
      sessionStorage.setItem("activepath", activepath);
      this.activepath = activepath;
    },
  },
  mounted() {
    // 调用事实时间的方法
    this.currentTime();
  },
  beforeUnmount() {
    // 销毁定时器
    if (this.getCurrentTime) {
      clearInterval(this.getCurrentTime); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>

<style scoped>
/* 左侧样式设置 */
.el-aside {
  background-color: #5f3988;
  height: 100vh;
}
/* 头部样式设置 */
.el-header {
  background-color: #fff;
}
/* 主体样式设置 */
.el-main {
  /* background-color: darkcyan; */
  background-color: rgb(249, 249, 249);
}

/* 用户头像设置 */
.heard_img {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heard_img img {
  width: 75px;
  height: 75px;
  /* background-color: #fff; */
  /* border-radius: 50%; */
}

/* 菜单栏样式 */
.el-submenu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 190px;
}

/* .el-menu-item {
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 13px;
} */
/* .first-menu {
  background-color: #5e6cf4;
} */
/* 菜单栏图标样式 */
/* .el-menu-item i, */
.el-menu i {
  font-size: 17px;
  color: #fff;
}
/* 退出按钮设置 */
.quit {
  float: right;
  margin-top: 8px;
}
/* 当前时间样式 */
.nowTime {
  width: 250px;
  height: 30px;
  line-height: 30px;
  float: right;
  margin-top: 8px;
  font-size: 15px;
  font-family: 宋体;
}

/* 小图标样式设置 */
.icon {
  width: 20px;
  margin-right: 10px;
}
</style>
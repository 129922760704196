import Vue from "vue"
import VueRouter from "vue-router"

// 路由组件的导入
import Login from "@/components/Login" //登录
import Home from "@/components/Home" //主界面
//导入子页面
// 欢迎界面
import Welcome from "@/pages/Welcome/Welcome.vue"


Vue.use(VueRouter);
const router = new VueRouter({
    routes: [{ path: "/", redirect: "Login" },
    { path: '/Login', component: Login },
    {
        path: '/Home', 
        component: Home,
        redirect: "/Welcome",
        children: [
            { path: "/Welcome", component: Welcome },
          
        ]
    }]
});

export default router;
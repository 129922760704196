<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
}
</script>


<style scoped>
*{
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div>
    <!-- 作品上传 -->
    <div class="contetn-max">
      <div class="contetn-max-left">
        <div class="welcome-top">
          <div class="welcome-top-stooing">
            <div>
              <el-input
                placeholder="请输入内容"
                v-model="from.remarks"
                clearable
              ></el-input>
              <el-button
                type="primary"
                class="welcome-top-stooing-buttom"
                @click="getworksPageList"
                >搜索</el-button
              >
            </div>
            <div style="margin-bottom: 10px">
              <el-checkbox
                style="margin-top: 10px"
                v-model="assTagchecked"
                @change="getworksPageList()"
                >票数排序</el-checkbox
              >
            </div>
          </div>
          <el-button
            type="success"
            @click="
              openDialogStatus = true;
              edoradd = true;
            "
            >上传作品</el-button
          >
        </div>
       
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          :row-style="{ height: '30px' }"
          :cell-style="{ padding: '8px', textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
          @cell-dblclick="copyText"
        >
          <el-table-column prop="title" label="团体名称" width="130"></el-table-column>
          <el-table-column label="宣传图" width="150">
            <template slot-scope="scope">
              <el-image
                style="width: 100px;"
                :src="scope.row.picUrl"
                :preview-src-list="[scope.row.picUrl]"
                fit="contain"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="videoUrl" label="视频链接" show-overflow-tooltip width="140"></el-table-column>
          <el-table-column prop="number" label="票数"></el-table-column>
          <el-table-column prop="sort" label="票数排名"></el-table-column>
          <el-table-column prop="views" label="热度"></el-table-column>
          <el-table-column prop="viewsSort" label="热度排名"></el-table-column>
          <el-table-column prop="name" label="舞者名称"></el-table-column>
          <el-table-column prop="phone" label="联系方式"></el-table-column>
          <!-- <el-table-column prop="top" label="华流推荐">
            <template slot-scope="scope">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                :title="
                  '确定' +
                  (scope.row.top == '-' ? '华流推荐' : '取消华流推荐') +
                  '吗？'
                "
                @confirm="operateDel(scope.row, 'recd')"
              >
                <el-button slot="reference" type="primary" size="mini">{{
                  scope.row.top == "-" ? "推荐" : "已推荐"
                }}</el-button>
              </el-popconfirm>
            </template>
          </el-table-column> -->
          <el-table-column prop="openUp" label="上/下架">
            <template slot-scope="scope">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                :title="
                  '确定' +
                  (scope.row.openUp === '下架' ? '上架' : '下架') +
                  '吗？'
                "
                @confirm="operateDel(scope.row, 'up')"
              >
                <el-button slot="reference" size="mini" type="warning">{{
                  scope.row.openUp
                }}</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="190" fixed="right">
            <template slot-scope="scope">
              <div class="ActionColumn">
                <el-link
                  @click="operateDel(scope.row, 'edit')"
                  class="ActionColumn_h"
                  type="primary"
                  >编辑</el-link
                >
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定删除吗？"
                  @confirm="operateDel(scope.row, 'del')"
                >
                  <el-link
                    slot="reference"
                    class="ActionColumn_h"
                    type="primary"
                    >删除</el-link
                  >
                </el-popconfirm>
                <el-link
                  @click="operateDel(scope.row, 'adj')"
                  class="ActionColumn_h"
                  type="primary"
                  >调整票数</el-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          style="margin-top: 18px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="from.pageNum"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="from.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="contetn-max-right">
        <el-card>
          <div style="font-weight: 600; margin-bottom: 10px">
            街舞盟主排行榜
          </div>
          <div
            class="RankingList-max"
            :class="{ 'RankingList-max-good': index % 2 == 0 }"
            v-for="(item, index) in RankingList"
            :key="index"
          >
            <div class="RankingList-max-title">
              {{ index + 1 }}. {{ item.title }}
            </div>
            <div class="RankingList-max-num">
              <img
                class="RankingList-max-img"
                src="@/assets/SupportHeat.png"
                alt=""
              />
              <label>{{ item.number }}</label>
            </div>
          </div>
        </el-card>
        <br>
        <el-card>
          <div style="font-weight: 600; margin-bottom: 10px">
            华流推荐榜
          </div>
          <div
            class="RankingList-max"
            :class="{ 'RankingList-max-good': index % 2 == 0 }"
            v-for="(item, index) in recommendList"
            :key="index"
          >
            <div class="RankingList-max-title">
              {{ index + 1 }}. {{ item.title }}
            </div>
            <div class="RankingList-max-num">
              <img
                class="RankingList-max-img"
                style="width:20px;height: 16px;margin-right: 5px;"
                src="https://cpop-1300336827.cos.accelerate.myqcloud.com/1670582284463.png"
                alt=""
              />
              <label>{{ item.number }}</label>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <el-dialog
      :title="!edoradd ? '编辑作品' : '上传作品'"
      :visible.sync="openDialogStatus"
      width="45%"
      @close="close"
    >
      <el-form :model="FromData" :rules="rules" ref="FromData">
        <el-form-item label="作品名称" label-width="130px" prop="title">
          <el-input
            v-model="FromData.title"
            autocomplete="off"
            placeholder="请输入作品名称"
          ></el-input>
        </el-form-item>
      <!-- </el-form> -->
      <!-- <el-form :model="FromData" :rules="rules" ref="FromData"> -->
        <el-form-item label="舞者名称" label-width="130px" prop="title">
          <el-input
            v-model="FromData.name"
            autocomplete="off"
            placeholder="请输入舞者名称"
          ></el-input>
        </el-form-item>
      <!-- </el-form> -->
      <!-- <el-form :model="FromData" :rules="rules" ref="FromData"> -->
        <el-form-item label="联系方式" label-width="130px" prop="title">
          <el-input
            v-model="FromData.phone"
            autocomplete="off"
            placeholder="请输入联系方式"
          ></el-input>
        </el-form-item>
      <!-- </el-form> -->
      <!-- <el-form :model="FromData" :rules="rules" ref="FromData"> -->
        <el-form-item label="作品上传" label-width="130px">
          <upload
            v-model="FromData.videoUrl"
            style="width: 400px"
            text="(上传类型MP4/WMv，200M内)"
            v-show="!FromData.videoUrl"
          ></upload>
          <div v-show="FromData.videoUrl" class="video-max">
            <video
              id="myvideo"
              class="video-style"
              controls
              :src="FromData.videoUrl"
            ></video>
            <i
              class="el-icon-circle-close close-btn"
              v-on:click="removeFile()"
            ></i>
          </div>
        </el-form-item>
        <!-- <el-form :model="FromData" :rules="rules" ref="FromData"> -->
        <el-form-item label="封面宣传" label-width="130px">
          <upload v-model="FromData.picUrl" style="width: 400px"></upload>
        </el-form-item>
      <!--  </el-form> -->
      </el-form> 
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogopenDialogStatusisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="add()" v-if="edoradd"
          >确 定</el-button
        >
        <el-button type="primary" @click="edit()" v-else>提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="openWorksStatus" width="35%">
      <div class="openWorksStatus-max">
        {{ Info.title }}
      </div>
      <div class="openWorksStatus-max">
        <div>
          票数：<label>{{ Info.number }}</label>
        </div>
        <div>
          目前排名：<label>{{ Info.sort }}</label>
        </div>
      </div>
      <div class="openWorksStatus-max">
        <div>
          浏览量：<label>{{ Info.views }}</label>
        </div>
      
      </div>
      <el-form :model="NumberOfVotes" ref="FromData">
        <el-form-item label="调整票数" label-width="130px">
          <el-input-number
            v-model="NumberOfVotes.number"
            :min="-10000000000000000000"
            width="60%"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="调整流量" label-width="130px">
          <el-input-number
            v-model="NumberOfVotes.views"
            :min="-10000000000000000000"
            width="60%"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="调整原因" label-width="130px">
          <el-input
            v-model="NumberOfVotes.remarks"
            autocomplete="off"
            placeholder="请输入调整原因"></el-input>
        </el-form-item>
      </el-form>
      <div class="openWorksStatus-button">
        <el-button type="primary" @click="adjustmentNumber">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import dynamicTableCopy from "@/components/dynamicTable-copy.vue";
import upload from "@/components/Upload";
import {upLoad} from "@/api/index"

import {
  worksPageList,
  worksAdd,
  worksUpd,
  worksDel,
  worksOpenUp,
  worksNumberUpd,
  worksCom,
  worksComList
} from "@/api/index";

export default {
  data() {
    return {
      tableData: [],
      RankingList: [],
      from: {
        pageNum: 1,
        pageSize: 10,
        remarks: "",
      },
      total: 0,
      radio: "1",

      openDialogStatus: false,
      openWorksStatus: false,

      FromData: {
        title: "",
        picUrl: "",
        videoUrl: "",
        videoMar: "",
        name:"",
        phone:"",
      },

      rules: {},
      NumberOfVotes: {
        number: 0,
        views:0,
        remarks: "",
      },
      assTagchecked: false,
      edoradd: true,
      Info: {
        title: "",
        number: "",
        sort: "",
        views:''
      },
      recommendList:[]
    };
  },
  watch: {
    "FromData.videoUrl": {
      // immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        console.log(this.FromData.videoUrl);
        if(this.FromData.videoUrl !== ''){
          this.findVideoCover();
        }
      },
    },
  },
  components: {
    upload,
  },
  created() {
    this.FromData.picUrl = ''
    this.getworksPageList();
  },
  methods: {
    findVideoCover() {
      if(!this.edoradd) return
      let size = 160
      const video = document.getElementById("myvideo");
      video.width = size
      video.height = size
      video.currentTime = 1 // 第一帧
      //创建canvas对象
      const canvas = document.createElement("canvas")
      canvas.width = size
      canvas.height = size
      this.$nextTick(()=>{
        // 利用canvas对象方法绘图
        canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        const img = canvas.toDataURL("image/jpeg") // 这个就是图片的base64
        this.upLoad(this.dataURLtoFile(img,"jpeg"))
      })
    },
    dataURLtoFile(dataurl, filename) {
        //将base64转换为文件
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },
    
    async upLoad(img){
       let param = new FormData();
      param.append("file", img);
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await upLoad(param)
      loading.close()
      console.log(res);
      console.log(this.FromData.picUrl);
      this.FromData.picUrl =  res.data.url
    },

    async getworksPageList() {
      this.from.status = this.assTagchecked ? "1" : "0";
      let res = await worksPageList(this.from);
      console.log(res);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      // this.tableLoading = false;
      let res2 = await worksPageList({
        pageNum: 1,
        pageSize: 20,
        status: "1",
      });
      this.RankingList = res2.data.list;

      let res3 = await worksComList({})
      console.log(res3);
      this.recommendList = res3.data
    },
    operateDel(e, btn) {
      console.log(e);
      switch (btn) {
        case "edit":
          this.FromData.title = e.title;
          this.FromData.picUrl = e.picUrl;
          this.FromData.videoUrl = e.videoUrl;
          this.FromData.videoMar = e.videoMar;
          this.FromData.worksId = e.worksId;
          this.FromData.name = e.name;
          this.FromData.phone = e.phone

          this.edoradd = false;
          this.openDialogStatus = true;
          break;
        case "up":
          this.operationUp(e.worksId);
          break;
        case "del":
          this.operationDel(e.worksId);
          break;
        case "adj":
          this.Info.title = e.title;
          this.Info.number = e.number;
          this.Info.sort = e.sort;
          this.Info.worksId = e.worksId;
          this.NumberOfVotes.number = e.number || 0;
          this.NumberOfVotes.views = e.views || 0;


          this.openWorksStatus = true;
          break;
        case "recd":
          this.operationRecd(e.worksId);
          break;
        default:
          break;
      }
    },
    // 上传作品
    async add() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.FromData.title === "") {
        loading.close();
        this.$message.error("请输入作品名称");
        return;
      }
      if (this.FromData.name === "") {
        loading.close();
        this.$message.error("请输入舞者名称");
        return;
      }
      // if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.FromData.phone))) {
      //   loading.close();
      //   this.$message.error("请填写正确的手机号");
      //   return;
      // }
      if (this.FromData.picUrl === "") {
        loading.close();
        this.$message.error("请上传封面");
        return;
      }
      if (this.FromData.videoUrl === "") {
        loading.close();
        this.$message.error("请上传作品");
        return;
      }
      var videoTime = document.getElementById("myvideo");
      this.FromData.videoMar = this.formatSeconds(videoTime.duration);
      let res = await worksAdd(this.FromData);
      loading.close();
      if (res.status === "success") {
        this.$notify({
          title: "提示",
          message: "上传成功",
          type: "success",
        });
        this.openDialogStatus = false;
        this.getworksPageList();
        this.FromData.picUrl = "";
        this.FromData.videoUrl = "";
      } else {
        this.$notify({
          title: "提示",
          message: res.msg,
          type: "error",
        });
      }
      console.log(res);
    },
    formatSeconds(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + parseInt(secondTime);

      if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + ":" + result;
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + ":" + result;
      }
      return result;
    },
    // 编辑
    async edit() {
      console.log(this.FromData);
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await worksUpd(this.FromData);
      loading.close();
      if (res.status === "success") {
        this.$notify({
          title: "提示",
          message: "操作成功",
          type: "success",
        });
        this.openDialogStatus = false;
        this.getworksPageList();
      } else {
        this.$notify({
          title: "提示",
          message: res.msg,
          type: "error",
        });
      }
    },
    // 调整票数
    async adjustmentNumber() {
      if ((this.NumberOfVotes.number === this.Info.number) && this.NumberOfVotes.views === this.Info.views) {
        console.log("微操作");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await worksNumberUpd({
        worksId: this.Info.worksId,
        number: this.NumberOfVotes.number,
        remarks: this.NumberOfVotes.remarks,
        views: this.NumberOfVotes.views,

      });
      loading.close();
      this.openWorksStatus = false;
      console.log(res);
      if (res.status === "success") {
        this.$notify({
          title: "提示",
          message: "操作成功",
          type: "success",
        });
        this.getworksPageList();
      } else {
        this.$notify({
          title: "提示",
          message: res.msg,
          type: "error",
        });
      }
    },
    // 删除
    async operationDel(worksId) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await worksDel({ worksId });
      loading.close();
      console.log(res);
      if (res.status === "success") {
        this.$notify({
          title: "提示",
          message: "操作成功",
          type: "success",
        });
        this.getworksPageList();
      } else {
        this.$notify({
          title: "提示",
          message: res.msg,
          type: "error",
        });
      }
    },
    // 上架
    async operationUp(worksId) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await worksOpenUp({ worksId });
      loading.close();
      console.log(res);
      if (res.status === "success") {
        this.$notify({
          title: "提示",
          message: "操作成功",
          type: "success",
        });
        this.getworksPageList();
      } else {
        this.$notify({
          title: "提示",
          message: res.msg,
          type: "error",
        });
      }
    },
    // 推荐
    async operationRecd(worksId) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await worksCom({ worksId });
      loading.close();
      console.log(res);
      if (res.status === "success") {
        this.$notify({
          title: "提示",
          message: "操作成功",
          type: "success",
        });
        this.getworksPageList();
      } else {
        this.$notify({
          title: "提示",
          message: res.msg,
          type: "error",
        });
      }
    },
    // 清空视频
    removeFile() {
      this.FromData.videoUrl = "";
    },

    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.from.pageSize = newsize;
      // 重新获取数据
      this.getworksPageList();
    },

    handleCurrentChange(newpage) {
      this.from.pageNum = newpage;
      // 重新获取数据
      this.getworksPageList();
    },
    close() {
      if (!this.edoradd) {
        this.FromData = {
          title: "",
          videoUrl: "",
          videoMar: "",
          picUrl: "",
        };
        this.FromData.picUrl = ''
        console.log(this.FromData.picUrl);
      }
    },
    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
};
</script>

<style scoped>
.contetn-max {
  width: 100%;
  display: flex;
}
.welcome-top {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.welcome-top-stooing-buttom {
  margin: 0 40px 0 15px;
}
.welcome-top-stooing,
.welcome-top-stooing div {
  display: flex;
  align-items: center;
  /* justify-centent:center; */
}
.contetn-max-left {
  width: 70%;
  padding-right: 15px;
}
.contetn-max-right {
  width: 30%;
}
.openWorksStatus-max {
  font-size: 18px;
  margin: 0 30px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.openWorksStatus-button {
  text-align: center;
  margin-top: 50px;
}
.ActionColumn {
  /* width: 70%; */
  /* padding-left: ; */
  /* background-color: #5e6cf4; */
  margin: auto;
  text-align: left;
}
.ActionColumn .ActionColumn_h {
  margin-right: 10px;
  font-size: 14px;
  /* color: #5e6cf4; */
}
.ActionColumn .ActionColumn_h:hover {
  text-decoration: underline;
  font-weight: bold;
}

/* 排行榜 */
.RankingList-max {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.RankingList-max-good {
  background-color: #e9e7f3;
}
.RankingList-max-title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.RankingList-max-num {
  display: flex;
  align-items: center;
}
.RankingList-max-img {
  width: 25px;
  height: 25px;
}
.video-max {
  width: 90%;
  /* background-color: aqua; */
  position: relative;
  font-size: 30px;
}
.video-style {
  width: 90%;
}
.close-btn {
  position: absolute;
  top: -5px;
  right: -5px;
}
</style>